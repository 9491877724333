import Highway from '@dogstudio/highway';
import { gsap } from "gsap";

export default class Facet extends Highway.Transition {

  out({ from, trigger, done }) {

    document.body.classList.add('cursor-wait');

    document.getElementById('results-loader').classList.remove('hidden');

    let results = from.querySelector('#results');
    gsap.to(results, {duration: .25, opacity: 0, onComplete:done});

  }

  in({ from, to, trigger, done }) {

    document.getElementById('results-loader').classList.add('hidden');

    // Results
    let newResults = to.querySelector('#results');
    let oldResults = from.querySelector('#results');
    let resultsWrapper = from.querySelector('#results-wrapper');
    oldResults.remove();
    resultsWrapper.append(newResults);

    // Categories
    let newCategories = to.querySelector('#categories');
    let oldCategories = from.querySelector('#categories');
    let categoriesWrapper = from.querySelector('#categories-wrapper');
    oldCategories.remove();
    categoriesWrapper.append(newCategories);

    to.remove();

    document.body.classList.remove('cursor-wait');
    gsap.fromTo(results, {opacity: 0}, {duration: .25, opacity: 1, onComplete:done});

  }

}
