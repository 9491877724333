import Swiper from "swiper";

export default class Program{
  constructor(){

    // Program swiper - DATES
    var datesEl = document.getElementById('program-dates');
    var showsEl = document.getElementById('program-shows');

    if(datesEl) {
      var datesSwiper = new Swiper(datesEl, {
        direction: 'horizontal',
        centeredSlides: true,
        slidesPerView: 3,
        spaceBetween: 5,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.program-swiper-button-next',
          prevEl: '.program-swiper-button-prev',
        },
        breakpoints: {
          // when window width is >= 480px
          992: {
            slidesPerView: datesEl.dataset.slidesperview,
            spaceBetween: 5
          },
        },
        on: {
          slideChange: function() {
            // Update the shows swiper
            showsSwiper.slideTo(this.activeIndex);
          }
        }
      });
    }

    if(showsEl) {
      var showsSwiper = new Swiper(showsEl, {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 0,
        autoHeight: true,
        on: {
          slideChange: function() {
            // Update the dates swiper
            datesSwiper.slideTo(this.activeIndex);
          }
        }
      });
    }

  }
}
