export default class Menu{
  constructor(){

    var menuOpener = document.querySelector('.menu-opener');
      var html = document.getElementsByTagName('html')[0];
      var gradient = document.getElementById('menu-gradient');
      menuOpener.onclick = function() {
          html.classList.toggle('menu-opened');
          gradient.classList.toggle('hidden');
          if (html.classList.contains('menu-opened')) {
            window.lockScroll.lock();
          } else {
            window.lockScroll.unlock();
          }
      }

  }

}
