import Highway from '@dogstudio/highway';
import CursorMagnetic from "../../cursorMagnetic";
import {gsap, TweenMax, ScrollToPlugin, Power3} from "gsap/all";
import video from "../../video";
import menu from "../../menu";
import Swiper from "swiper";
import Program from "../../program";
import Plyr from 'plyr';


export default class ShowRenderer extends Highway.Renderer {

  // Hooks/methods
  onEnter() {

    new CursorMagnetic();
    gsap.fromTo(".footer", {opacity: 0}, {opacity: 1, duration: .3, ease: "power3.out"});

    var mySwiper = new Swiper('.swiper-gallery-container', {
      direction: 'horizontal',
      loop: false,
      centeredSlides: false,
      slidesPerView: 2,
      spaceBetween: 16,
      navigation: {
        nextEl: '.next-btn',
        prevEl: '.prev-btn',
      },
    });

    var plyrVideos = document.querySelectorAll('.plyr-video');

    const players = Array.from(plyrVideos).map(p => new Plyr(p));

    let galleries = document.querySelectorAll('.swiper-gallery-container');

    galleries.forEach(gallery => {

        new Swiper(gallery, {
            slidesPerView: 1,
            breakpoints: {
              640: {
                slidesPerView: 2
              }
            },
            spaceBetween: 30,
            autoHeight: false,
            loop: false,
            navigation: {
                nextEl: '.next-btn',
                prevEl: '.prev-btn',
            },
        });
    });

  }

  onLeave() {
    gsap.fromTo(".footer", {opacity: 1}, {opacity: 0, duration: .3, ease: "power3.out"});
    document.getElementsByTagName('html')[0].classList.remove("menu-opened");
    window.lockScroll.unlock();
  }

  onEnterCompleted() {
    new video();
    new menu();
    new Program();
  }

  onLeaveCompleted() {

  }

}
