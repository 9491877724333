import {gsap, Power0, TweenMax, Back, TimelineMax, ScrollToPlugin} from "gsap";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import Splitting from 'splitting';
import 'splitting/dist/splitting.css';

import * as ScrollMagic from "scrollmagic";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export default class animation {
  constructor() {

    var button = document.querySelectorAll('.btn');

    button.forEach(function(item){
      item.appendChild(document.createElement('span'));
      item.addEventListener('mousemove', function(e){
        var btnCircle = item.querySelector('span');
        var btnCircleWidth = item.querySelector('span').offsetWidth;
        var btnCircleHeight = item.querySelector('span').offsetHeight;
        var viewportOffset = e.target.getBoundingClientRect();
        var relX = (event.clientX - viewportOffset.left) - (btnCircleWidth / 2);
        var relY = (event.clientY - viewportOffset.top) - (btnCircleHeight / 2);
        btnCircle.style.top = relY + 'px';
        btnCircle.style.left = relX + 'px';
      });
    });

    new Splitting();
    Splitting({
      target: "[data-splitting]",
      by: "lines",
    });
    gsap.set("[data-splitting]", {opacity: 1});

    // init controller
    var controller = new ScrollMagic.Controller();

    document.querySelectorAll('main h1.splitting, main h2.splitting, main h3.splitting, main h4.splitting, main div.splitting p').forEach(function (words) {
      var action = gsap.timeline()
        .fromTo(words.querySelectorAll('.word'), {
          opacity: 0,
          translateY: 50
        }, {
          opacity: 1,
          translateY: 0,
          ease: "power3.out",
          duration: 0.35,
          delay: 0,
          stagger: {
            amount: .15,
            from: 0
          }
        });

      new ScrollMagic.Scene({
        triggerElement: words,
        duration: 0,
        triggerHook: 0.90,
        reverse: false
      })
        .setTween(action)
        //.addIndicators()
        .addTo(controller);

    });

    document.querySelectorAll('main p.splitting').forEach(function (paragraph) {
      var action = gsap.timeline()
        .fromTo(paragraph.querySelectorAll('.word'), {
          opacity: 0,
          translateY: 30
        }, {
          opacity: 1,
          translateY: 0,
          ease: "power3.out",
          duration: 0.5,
          delay: 0.25,
          stagger: {
            amount: .15,
            from: 0
          }
        });

      new ScrollMagic.Scene({
        triggerElement: paragraph,
        duration: 0,
        triggerHook: 0.9,
        reverse: false
      })
        .setTween(action)
        //.addIndicators()
        .addTo(controller);

    });

    document.querySelectorAll('footer .grid').forEach(function (footerItem) {
      var action = gsap.timeline()
        .fromTo(footerItem.querySelectorAll('.footer-item'), {
          opacity: 0,
          translateY: 30
        }, {
          opacity: 1,
          translateY: 0,
          ease: "power3.out",
          duration: 0.5,
          delay: 0.25,
          stagger: {
            amount: .15,
            from: 0
          }
        });

      new ScrollMagic.Scene({
        triggerElement: footerItem,
        duration: 0,
        triggerHook: .9,
        reverse: false
      })
        .setTween(action)
        //.addIndicators()
        .addTo(controller);

    });

    document.querySelectorAll('main .image-cover').forEach(function (imageCover) {
      var action = gsap.timeline()
        .fromTo(imageCover, {
          opacity: 0,
          scale: 0.95,
          translateY: 15,
        }, {
          opacity: 1,
          scale: 1,
          translateY: 0,
          ease: "power3.out",
          duration: 0.75,
          delay: 0,
          stagger: {
            amount: .15,
            from: 0
          }
        });

      new ScrollMagic.Scene({
        triggerElement: imageCover,
        duration: 0,
        triggerHook: 0.9,
        reverse: false
      })
        .setTween(action)
        //.addIndicators()
        .addTo(controller);

    });

    document.querySelectorAll('.swiper-pictures .swiper-wrapper').forEach(function (swiperSlide) {
      var action = gsap.timeline()
        .fromTo(swiperSlide.querySelectorAll('.swiper-slide-visible'), {
          opacity: 0,
          translateY: 100
        }, {
          opacity: 1,
          translateY: 0,
          ease: "power3.out",
          duration: 0.5,
          delay: 0,
          stagger: {
            amount: .3,
            from: 0,
          }
        });

      new ScrollMagic.Scene({
        triggerElement: swiperSlide,
        duration: 0,
        triggerHook: 0.9,
        reverse: false
      })
        .setTween(action)
        //.addIndicators()
        .addTo(controller);

    });

    document.querySelectorAll('main .btn').forEach(function (button) {
      var action = gsap.timeline()
        .fromTo(button, {
          opacity: 0,
          translateY: 50
        }, {
          opacity: 1,
          translateY: 0,
          ease: "power3.out",
          duration: 0.5,
          delay: 0,
          stagger: {
            amount: .3,
            from: 0,
          }
        });

      new ScrollMagic.Scene({
        triggerElement: button,
        duration: 0,
        triggerHook: 0.9,
        reverse: false
      })
        .setTween(action)
        //.addIndicators()
        .addTo(controller);

    });

    document.querySelectorAll('.partners-container').forEach(function (partnerLogo) {
      var action = gsap.timeline()
        .fromTo(partnerLogo.querySelectorAll('.partners-logo'), {
          opacity: 0,
          translateY: 100,
        }, {
          opacity: 1,
          translateY: 0,
          ease: "power3.out",
          duration: 0.5,
          delay: 0,
          stagger: {
            amount: .2,
            from: 0,
          }
        });

      new ScrollMagic.Scene({
        triggerElement: partnerLogo,
        duration: 0,
        triggerHook: 0.9,
        reverse: false
      })
        .setTween(action)
        //.addIndicators()
        .addTo(controller);

    });

  }
}
