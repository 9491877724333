import Highway from '@dogstudio/highway';
import { gsap, TweenMax, ScrollToPlugin, Power3 } from "gsap/all";
import video from "../../video";
import menu from "../../menu";
import Swiper from 'swiper';

export default class ExplorationsRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {

    let galleries = document.querySelectorAll('.swiper-gallery-container');
    galleries.forEach(gallery => {
      new Swiper(gallery, {
        slidesPerView: 1,
        breakpoints: {
          640: {
            slidesPerView: 2
          }
        },
        spaceBetween: 30,
        autoHeight: false,
        loop: false,
        navigation: {
          nextEl: '.next-btn',
          prevEl: '.prev-btn',
        },
      });
    });

    gsap.fromTo(".footer", {opacity: 0}, {opacity: 1, duration: .3, ease: "power3.out"});
  }
  onLeave() {
    gsap.fromTo(".footer", {opacity: 1}, {opacity: 0, duration: .3, ease: "power3.out"});
    document.getElementsByTagName('html')[0].classList.remove("menu-opened");
    window.lockScroll.unlock();
  }
  onEnterCompleted() {
    new video();
    new menu();
  }
  onLeaveCompleted() {


  }
}
