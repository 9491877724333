import Alpine from 'alpinejs';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

window.smoothScroll = function(target, duration, ease) {

	var duration = duration ? duration : 1,
			to = null,
			ease = duration ? ease : 'Expo.easeInOut';

  if(target == 'top') {
    to = 0;
  } else if(target == 'bottom') {
    to = document.body.scrollHeight;
  } else {
    to = document.querySelector(target.hash);
  }

  gsap.to(window, {
    scrollTo: { y: to },
    duration: duration,
    ease: ease,
  });

}
