import './scss/app.scss';

import 'alpine-magic-helpers/dist/component'
import 'alpinejs';
import CustomAlpine from "./js/alpine";

import AdminTabs from "./js/adminTabs";
import CursorMagnetic from "./js/cursorMagnetic";
import ParticleJs from "./js/particles";
import animation from "./js/animation";
import Highway from '@dogstudio/highway';
import Cookies from "@explose/cookies";
import Analytics from "@explose/analytics";
import { Modal } from "@explose/alpine";

import LockScroll from "./js/lockScroll";

import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

// Renderers
import HomeRenderer from "./js/highway/renderer/home-renderer";
import NewsRenderer from "./js/highway/renderer/news-renderer";
import ShowRenderer from "./js/highway/renderer/show-renderer";
import ParagraphRenderer from "./js/highway/renderer/paragraph-renderer";
import ExplorationsRenderer from "./js/highway/renderer/explorations-renderer";

//Transitions
import Facet from "./js/highway/transition/facet";
import Loading from "./js/highway/transition/loading";

// Import Polyfills
// See: https://github.com/w3c/IntersectionObserver/tree/master/polyfill
import 'intersection-observer';

// Import Quicklink
// See: https://github.com/GoogleChromeLabs/quicklink
import { listen, prefetch } from "quicklink";

//Admin
new AdminTabs();

window.analytics = new Analytics({
  id:'G-DL93JRSG4S'
});


//GDPR
document.addEventListener('cookies', function(e){
  if(e.detail.enabled) {
    console.log('start tracking');
    window.analytics.track();
  } else {
    console.log('stop tracking');
  }
});
window.cookies = new Cookies({ enabledByDefault: true });


/***
 * Highway
 */

// Call Highway.Core once.
// Store it in a variable to use events
const H = new Highway.Core({
    renderers: {
      homepage: HomeRenderer,
      about: HomeRenderer,
      usefulinfo: HomeRenderer,
      explorations: ExplorationsRenderer,
      shows: ShowRenderer,
      partners: HomeRenderer,
      jobs: HomeRenderer,
      news: NewsRenderer,
      faq: HomeRenderer,
      cookies: HomeRenderer,
      error: HomeRenderer,
      jobFull: HomeRenderer,
      subPage: NewsRenderer,
      articleFull: NewsRenderer,
      projectFull: NewsRenderer,
      donations: HomeRenderer,
      tickets: HomeRenderer,
      projects: HomeRenderer,
      legalmention: HomeRenderer,
      paragraph: ParagraphRenderer,
      default: HomeRenderer,
    },
    transitions: {
      default: Loading,
      contextual: {
        facet: Facet
      }
    }
  }
);

// Detach admin links
if (document.getElementById('admin-tools')) {
  H.detach(
    document.querySelectorAll('a')
  )
}


window.cursor = new CursorMagnetic();
//window.particle = new ParticleJs();
window.lockScroll = new LockScroll();


// Listen Events

const links = document.querySelectorAll('.menu-wrapper a');

H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
  for (let i = 0; i < links.length; i++) {
    const link = links[i];

    // Clean class
    link.classList.remove('is-active');
    // Active link
    if (location.href.indexOf(link.href) > -1) {
      link.classList.add('is-active');
    }
  }
});

H.on('NAVIGATE_END', ({ to, location }) => {
  // Call Quicklink
  listen();

  if (window.cookies.isEnabled){
    window.analytics.track(to, location);
  }

  if (to.view.dataset.hrefFr) document.querySelector('.language-switcher').querySelector('a[hreflang=fr]').href = to.view.dataset.hrefFr;
  if (to.view.dataset.hrefEn) document.querySelector('.language-switcher').querySelector('a[hreflang=en]').href = to.view.dataset.hrefEn;
  if (to.view.dataset.hrefDe) document.querySelector('.language-switcher').querySelector('a[hreflang=de]').href = to.view.dataset.hrefDe;

});


window.addEventListener('load', () =>{
  // Call Quicklink
  listen();
  new animation();
});
