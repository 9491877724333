import Highway from '@dogstudio/highway';
import CursorMagnetic from "../../cursorMagnetic";
import {gsap, TweenMax, ScrollToPlugin, Power3} from "gsap/all";
import video from "../../video";
import menu from "../../menu";
import Swiper from "swiper";
import Program from "../../program";

export default class ShowRenderer extends Highway.Renderer {

  // Hooks/methods
  onEnter() {

    new CursorMagnetic();
    gsap.fromTo(".footer", {opacity: 0}, {opacity: 1, duration: .3, ease: "power3.out"});

    var mySwiper = new Swiper('.show-swiper-container', {
      direction: 'horizontal',
      loop: true,
      effect: 'fade',
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.next-btn',
        prevEl: '.prev-btn',
      },
    });

  }

  onLeave() {
    gsap.fromTo(".footer", {opacity: 1}, {opacity: 0, duration: .3, ease: "power3.out"});
    document.getElementsByTagName('html')[0].classList.remove("menu-opened");
    window.lockScroll.unlock();
  }

  onEnterCompleted() {
    new video();
    new menu();
    new Program();
  }

  onLeaveCompleted() {

  }

}
