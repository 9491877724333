import { gsap, TweenMax } from "gsap";

export default class CursorMagnetic {
   constructor() {

    // Only for screens bigger than 640px
    if(!window.matchMedia("(min-width: 640px)").matches) {
      return false;
    }

    const lerp = (a, b, n) => ((1 - n) * a) + (n * b);
    const round = (numb) => Math.round(numb * 100) / 100;

		class Cursor {
			constructor(el, btns) {
				this.el = el || document.querySelector('[js-cursor]');
				this.buttons = btns || Array.from(document.querySelectorAll('[data-mouse]'));

				this.isLerp = true;

				this.mouse = { x: 0, y: 0 };
				this.interMouse = { x: 0, y: 0 };
				this.norm = { x: 0, y: 0 };

				this.attraction = 10;
				this.magnetism = 2;
			}

			init() {
				this.bindEvents();
				this.render();
			}

			render() {
				this.setPos();

				window.requestAnimationFrame(this.render.bind(this));
			}

			setPos() {
				const lerpedX = lerp(this.mouse.x, this.interMouse.x, 0.75);
				const lerpedY = lerp(this.mouse.y, this.interMouse.y, 0.75);

				this.interMouse.x = this.isLerp ? lerpedX - (this.norm.x / this.magnetism) : this.mouse.x;
				this.interMouse.y = this.isLerp ? lerpedY - (this.norm.y / this.magnetism) : this.mouse.y;

				this.el.style.transform = `
					translateY(${ this.interMouse.y }px)
					translateX(${ this.interMouse.x }px)
				`;
			}

			mousePosition(event) {
				this.mouse = {
				x: event.clientX,
				y: event.clientY,
				};
			}

			bindEvents() {
				window.addEventListener('mousemove', this.mousePosition.bind(this));

				this.buttons.forEach((btn) => {
					this.attraction = btn.getAttribute('data-mouse-attractionVal') || this.attraction;
					this.magnetism = btn.getAttribute('data-mouse-magnetism') || this.magnetism;
					const state = btn.getAttribute('data-mouse-attractionState');

					if (state === 'active') btn.addEventListener('mousemove', this.setPositionButton.bind(this, btn));
					btn.addEventListener('mouseenter', this.setHoverMouse.bind(this, btn));
					btn.addEventListener('mouseleave', this.resetButton.bind(this, btn, state));
				});
			}

			setHoverMouse(el) {
				this.el.setAttribute('data-hover', el.getAttribute('data-hover-type'));
			}

			setPositionButton(el) {
				this.dim = {
					x: el.clientWidth / 2,
					y: el.clientHeight / 2,
				};

				this.norm = {
						x: round((event.offsetX - this.dim.x) / this.dim.x) * this.attraction,
						y: round((event.offsetY - this.dim.y) / this.dim.y) * this.attraction,
				};

				this.animateButton(el);
			}

			animateButton(btn) {
				gsap.to(btn, 0.5, {
					xPercent: this.norm.x,
					yPercent: this.norm.y,
				});
			}

			resetButton(el, state) {
				this.el.setAttribute('data-hover', '');

				if (state === 'active' || state) {
					this.norm = { x: 0, y: 0 };
					this.animateButton(el);
				}
			}
		}

		const cursor = new Cursor();
		cursor.init();

 }
}
