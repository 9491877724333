import Plyr from 'plyr';

import { gsap, TweenMax, ScrollToPlugin, Power3 } from "gsap/all";

 export default class video {
   constructor() {

      var player = new Plyr('#player', {
         controls: ['play', 'progress', 'mute'],
       });

      var videoOpener = document.querySelector('.video-opener');
      var videoCloser = document.querySelector('.video-closer');
      var html = document.getElementsByTagName('html')[0];

      if(typeof(videoOpener) != 'undefined' && videoOpener != null){
         videoOpener.onclick = function() {
           console.log('addClass video-opened');
            html.classList.toggle('video-opened');
            setTimeout(function(){ player.play(); window.lockScroll.lock(0); }, 500);
            gsap.registerPlugin(ScrollToPlugin);
            TweenMax.to(window, 0.5, {scrollTo: {y: 0}});
         }
      }

      if(typeof(videoCloser) != 'undefined' && videoCloser != null){
         videoCloser.onclick = function() {
           console.log('removeClass video-opened');
            html.classList.remove('video-opened');
            player.pause();
            window.lockScroll.unlock();
         }
      }

   }
}
