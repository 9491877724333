import Highway from '@dogstudio/highway';
import { gsap, TweenMax, ScrollToPlugin, Power3 } from "gsap/all";
import menu from "../../menu";
import Swiper from 'swiper';

import Plyr from 'plyr';

export default class NewsRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {

    Array.from(document.querySelectorAll('a[data-drupal-facet-item-id]')).map(a => a.setAttribute('data-transition','facet'));

    gsap.fromTo(".footer", {opacity: 0}, {opacity: 1, duration: .3, ease: "power3.out"});

    var mySwiper = new Swiper('.swiper-news-container', {
      direction: 'horizontal',
      loop: true,
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 150,
      autoHeight: true,
      navigation: {
        nextEl: '.next-btn',
        prevEl: '.prev-btn',
      },
    });

    var plyrVideos = document.querySelectorAll('.plyr-video');


    console.log(plyrVideos);
    const players = Array.from(plyrVideos).map(p => new Plyr(p));



    let galleries = document.querySelectorAll('.swiper-gallery-container');

    galleries.forEach(gallery => {

        new Swiper(gallery, {
            slidesPerView: 1,
            breakpoints: {
              640: {
                slidesPerView: 2
              }
            },
            spaceBetween: 30,
            autoHeight: false,
            loop: false,
            navigation: {
                nextEl: '.next-btn',
                prevEl: '.prev-btn',
            },
        });
    });

  }
  onLeave() {
    gsap.fromTo(".footer", {opacity: 1}, {opacity: 0, duration: .3, ease: "power3.out"});
    document.getElementsByTagName('html')[0].classList.remove("menu-opened");
    window.lockScroll.unlock();

  }
  onEnterCompleted() {
    new menu();
  }
  onLeaveCompleted() {


  }
}
