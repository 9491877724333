import Highway from '@dogstudio/highway';
import { gsap, TweenMax, ScrollToPlugin, Power3 } from "gsap/all";
import animation from "../../animation";

export default class Loading extends Highway.Transition {

  out({ from, trigger, done }) {

    // Start loading mask
    document.getElementsByTagName('html')[0].classList.add("loading");

    // Fade the FROM
    gsap.to(from, {duration: .5, opacity: 0, ease: "power3.out", onComplete: function() {

      //Scroll to top
      gsap.registerPlugin(ScrollToPlugin);
      gsap.to(window, {duration: .5, scrollTo: {y: 0, autoKill: true}, opacity: 0, ease: "power3"});

      done();
    }});



  }

  in({ from, to, trigger, done }) {

    from.remove();
    document.getElementsByTagName('html')[0].classList.add("loaded");
    new animation();
    done();

    setTimeout(function() {
      document.getElementsByTagName('html')[0].classList.remove("loading");
      document.getElementsByTagName('html')[0].classList.remove("loaded");
    }, 400);



  }

}
