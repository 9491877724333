import Highway from '@dogstudio/highway';
import CursorMagnetic from "../../cursorMagnetic";
import { gsap, TweenMax, ScrollToPlugin, Power3 } from "gsap/all";
import video from "../../video";
import menu from "../../menu";
import mapboxgl from "mapbox-gl";
import Swiper from "swiper";
import Program from "../../program";


export default class HomeRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        new CursorMagnetic();

        if(document.getElementById('testimonials')) {
          var testimonialsSwiper = new Swiper(document.getElementById('testimonials'), {
            direction: 'horizontal',
            centeredSlides: true,
            slidesPerView: 1,
            spaceBetween: 0,
            autoHeight: true,
            autoplay: {
              delay: 10000,
              disableOnInteraction: false,
            },
            pagination: {
              el: '.swiper-pagination',
            },
          });
        }

        gsap.fromTo(".footer", { opacity: 0 }, { opacity: 1, duration: .3, ease: "power3.out" });
    }
    onLeave() {
        gsap.fromTo(".footer", { opacity: 1 }, { opacity: 0, duration: .3, ease: "power3.out" });
        document.getElementsByTagName('html')[0].classList.remove("menu-opened");
        window.lockScroll.unlock();

    }
    onEnterCompleted() {
        new video();
        new menu();
        new Program();
    }
    onLeaveCompleted() {


    }
}
